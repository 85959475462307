import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Store } from "projects/aloe2-library/src/lib/store";
import { LOAD_SELECTED_REPORT_ACTION } from "projects/aloe2-main/src/data/models/report.model";

@Injectable({
    providedIn: 'root'
})
export class ReportResolver  {

    constructor(private store: Store) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        const reportId = route.queryParams.id;
        this.store.dispatch(LOAD_SELECTED_REPORT_ACTION, reportId);

        return {
            reportId
        }
    }
}